<template>
  <div>
    <section>
      <article class="mt-5">
        <div class="container">
          <div class="mb-3">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item>VUEX</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <!-- {{ localStorage.getItem("user") }} -->

          <div>
            <p>
              {{ $store.state.accessToken }}
            </p>
            <p>
              {{ $store.state.decodedToken }}
            </p>
            <p>{{ $store.state.counter }}</p>
            <p>The getters:: {{ another }}</p>
            <p>The getters:: {{ checkingAnother }}</p>

            <p>{{ $store.state.fullName }}</p>
            <button
              @click="incrementCounter"
              class="btn btn-success"
            >Increment By 1</button>
            <button
              @click="increase({ value: 2 })"
              class="btn btn-success"
            >Increment By 10</button>
          </div>

          {{ userToken }}
          <br />
          {{ decoded }}

        </div>
      </article>
    </section>
  </div>
</template>

<script>
///Import mapGetters function from VUEX
import { mapGetters } from "vuex";

///Import mapActions function from VUEX
import { mapActions } from "vuex";

export default {
  data() {
    return {
      userToken: null,
      decoded: {},
    };
  },

  computed: {
    // another() {
    //   return $store.getters.another;
    // },

    // checkingAnother() {
    //   return $store.getters.checkingAnother;
    // },

    //Call mapGetters with the spread operator, which returns and object
    //that now merges into the computed property
    //mapGetters takes an array as an argument, and in the array, you list all the getter names as strings,
    //which you want to have as computed properties in this component.
    ...mapGetters(["another", "checkingAnother"]),
  },

  methods: {
    ...mapActions(["incrementCounter", "increase"]),
    // addByOne() {
    //   this.$store.dispatch("incrementCounter");
    // },

    // addByTen() {
    //   this.$store.dispatch("increase", { value: 2 });
    // },
  },
};
</script>

<style scoped>
</style>